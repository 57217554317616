import { TextField, Autocomplete } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';

export interface AutocompleteSelectOptions {
  label: string;
  value: number | string;
}

interface ControlledSelectInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: string;
  options: {
    label: string;
    value: number | string;
  }[];
  controllerProps?: any;
  inputsProps?: any;
  errors: any;
  isDirty: boolean;
}

const ControlledSelectInput = <T extends FieldValues>(
  props: ControlledSelectInputProps<T>
) => {
  return (
    <Controller
      {...props.controllerProps}
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange } }) => {
        const selectedOption =
          props.options.find((option) => option.value === value) || null;

        return (
          <Autocomplete
            value={selectedOption}
            {...props.inputsProps}
            disablePortal
            options={props.options}
            getOptionLabel={(option: any) => option.label}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            renderOption={(props, option: any) => {
              return (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params: any) => {
              return (
                <TextField
                  {...params}
                  label={props.label}
                  helperText={
                    props.isDirty && props.errors && props.errors.message
                  }
                  error={props.isDirty && Boolean(props.errors)}
                />
              );
            }}
            onChange={(e: any, data: any) => onChange(data?.value)}
          />
        );
      }}
    />
  );
};

export default ControlledSelectInput;
